import { VIEWS_CHART_COUNT } from "../../utils/constants";

// auth action types
export const REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS";
export const REGISTRATION_FAILURE = "REGISTRATION_FAILURE";

// Request Demo
export const REQUEST_DEMO_LOADING = "REQUEST_DEMO_LOADING";
export const REQUEST_DEMO_SUCCESS = "REQUEST_DEMO_SUCCESS";
export const REQUEST_DEMO_FAILURE = "REQUEST_DEMO_FAILURE";

// user
export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const USER_LOGIN_ERROR = "USER_LOGIN_ERROR";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const USER_LOGOUT_ERROR = "USER_LOGOUT_ERROR";
export const AUTH_LOADING = "AUTH_LOADING"; // for all kind of auth stuffs (login, register, reset password bla bla bla)
export const USER_INFO_LOADED = "USER_INFO_LOADED";
export const USER_INFO_LOAD_ERROR = "USER_INFO_LOAD_ERROR";
export const TOGGLE_FORGOT_PASSWORD = "TOGGLE_FORGOT_PASSWORD";
export const USER_FORGOT_PASSWORD_SUCCESS = "USER_FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const RESET_PASSWORD_STATUS = "RESET_PASSWORD_STATUS";
export const CHANGE_PASSWORD_ERRORS = "CHANGE_PASSWORD_ERRORS";
export const PASSWORD_CHANGED = "PASSWORD_CHANGED";

export const PROFILE_UPDATE_ERRORS = "PROFILE_UPDATE_ERRORS";
export const PROFILE_UPDATED = "PROFILE_UPDATED";
export const FCM_UPPDATED = "FCM_UPPDATED";

// template
export const SET_ACTIVE_BRAND = "SET_ACTIVE_BRAND";
export const SET_ACTIVE_COMPANY = "SET_ACTIVE_COMPANY";
export const TOGGLE_DARK_PICTURES = "TOGGLE_DARK_PICTURES";
export const TEMPLATE_DATA_LOADING = "TEMPLATE_DATA_LOADING";
export const TEMPLATE_DATA_LOADED = "TEMPLATE_DATA_LOADED";
export const TEMPLATE_DATA_LOAD_ERROR = "TEMPLATE_DATA_LOAD_ERROR";

// nuggets data
export const SALES_NUGGETS_FETCHED = "SALES_NUGGETS_FETCHED";
export const ADS_NUGGETS_FETCHED = "ADS_NUGGETS_FETCHED";

// DATA IDENTIFIERS
export const DATA_IDENTIFIERS_LOADING = "DATA_IDENTIFIERS_LOADING";
export const DATA_IDENTIFIERS_SUCCESS = "DATA_IDENTIFIERS_SUCCESS";
export const DATA_IDENTIFIERS_FAILURE = "DATA_IDENTIFIERS_FAILURE";

export const salesNuggetActionTypes = {};
for (
  let i = VIEWS_CHART_COUNT.sales.nugget.start;
  i < VIEWS_CHART_COUNT.sales.nugget.end;
  i++
) {
  salesNuggetActionTypes[
    `SALES_NUGGET_${i}_DATA_LOADING`
  ] = `SALES_NUGGET_${i}_DATA_LOADING`;
  salesNuggetActionTypes[
    `SALES_NUGGET_${i}_DATA_LOADED`
  ] = `SALES_NUGGET_${i}_DATA_LOADED`;
  salesNuggetActionTypes[
    `SALES_NUGGET_${i}_DATA_LOAD_ERROR`
  ] = `SALES_NUGGET_${i}_DATA_LOAD_ERROR`;
}

// charts data
export const salesChartActionTypes = {};
for (
  let i = VIEWS_CHART_COUNT.sales.chart.start;
  i < VIEWS_CHART_COUNT.sales.chart.end;
  i++
) {
  salesChartActionTypes[
    `SALES_CHART_${i}_DATA_LOADING`
  ] = `SALES_CHART_${i}_DATA_LOADING`;
  salesChartActionTypes[
    `SALES_CHART_${i}_DATA_LOADED`
  ] = `CHART_${i}_DATA_LOADED`;
  salesChartActionTypes[
    `SALES_CHART_${i}_DATA_LOAD_ERROR`
  ] = `SALES_CHART_${i}_DATA_LOAD_ERROR`;
}

// ads nuggets data
export const adsNuggetActionTypes = {};
for (
  let i = VIEWS_CHART_COUNT.ads.nugget.start;
  i < VIEWS_CHART_COUNT.ads.nugget.end;
  i++
) {
  adsNuggetActionTypes[
    `ADS_NUGGET_${i}_DATA_LOADING`
  ] = `ADS_NUGGET_${i}_DATA_LOADING`;
  adsNuggetActionTypes[
    `ADS_NUGGET_${i}_DATA_LOADED`
  ] = `ADS_NUGGET_${i}_DATA_LOADED`;
  adsNuggetActionTypes[
    `ADS_NUGGET_${i}_DATA_LOAD_ERROR`
  ] = `ADS_NUGGET${i}_DATA_LOAD_ERROR`;
}

// ads charts data
export const adsChartActionTypes = {};
for (
  let i = VIEWS_CHART_COUNT.ads.chart.start;
  i < VIEWS_CHART_COUNT.ads.chart.end;
  i++
) {
  adsChartActionTypes[
    `ADS_CHART_${i}_DATA_LOADING`
  ] = `ADS_CHART_${i}_DATA_LOADING`;
  adsChartActionTypes[
    `ADS_CHART_${i}_DATA_LOADED`
  ] = `ADS_CHART_${i}_DATA_LOADED`;
  adsChartActionTypes[
    `ADS_CHART_${i}_DATA_LOAD_ERROR`
  ] = `ADS_CHART_${i}_DATA_LOAD_ERROR`;
}

// Analytics Charts Data
export const primaryAnalyticsChartActionTypes = {};
for (
  let i = VIEWS_CHART_COUNT.primaryAnalytics.chart.start;
  i < VIEWS_CHART_COUNT.primaryAnalytics.chart.end;
  i++
) {
  primaryAnalyticsChartActionTypes[
    `PRIMARY_ANALYTICS_CHART_${i}_DATA_LOADING`
  ] = `PRIMARY_ANALYTICS_CHART_${i}_DATA_LOADING`;
  primaryAnalyticsChartActionTypes[
    `PRIMARY_ANALYTICS_CHART_${i}_DATA_LOADED`
  ] = `PRIMARY_ANALYTICS_CHART_${i}_DATA_LOADED`;
  primaryAnalyticsChartActionTypes[
    `PRIMARY_ANALYTICS_CHART_${i}_DATA_LOAD_ERROR`
  ] = `PRIMARY_ANALYTICS_CHART_${i}_DATA_LOAD_ERROR`;
}

export const detailedAnalyticsChartActionTypes = {};
for (
  let i = VIEWS_CHART_COUNT.detailedAnalytics.chart.start;
  i < VIEWS_CHART_COUNT.detailedAnalytics.chart.end;
  i++
) {
  detailedAnalyticsChartActionTypes[
    `DETAILED_ANALYTICS_CHART_${i}_DATA_LOADING`
  ] = `DETAILED_ANALYTICS_CHART_${i}_DATA_LOADING`;
  detailedAnalyticsChartActionTypes[
    `DETAILED_ANALYTICS_CHART_${i}_DATA_LOADED`
  ] = `DETAILED_ANALYTICS_CHART_${i}_DATA_LOADED`;
  detailedAnalyticsChartActionTypes[
    `DETAILED_ANALYTICS_CHART_${i}_DATA_LOAD_ERROR`
  ] = `DETAILED_ANALYTICS_CHART_${i}_DATA_LOAD_ERROR`;
}

export const dashboardChartActionTypes = {};
for (let i = 1; i < 9; i++) {
  dashboardChartActionTypes[
    `DASHBOARD_CHART_${i}_DATA_LOADING`
  ] = `DASHBOARD_CHART_${i}_DATA_LOADING`;
  dashboardChartActionTypes[
    `DASHBOARD_CHART_${i}_DATA_LOADED`
  ] = `DASHBOARD_CHART_${i}_DATA_LOADED`;
  dashboardChartActionTypes[
    `DASHBOARD_CHART_${i}_DATA_LOAD_ERROR`
  ] = `DASHBOARD_CHART_${i}_DATA_LOAD_ERROR`;
}

export const RESET_SALES_CHART_DATA = "RESET_SALES_CHART_DATA";
export const RESET_ADS_CHART_DATA = "RESET_ADS_CHART_DATA";
export const RESET_PRIMARY_ANALYTICS_CHART_DATA =
  "RESET_PRIMARY_ANALYTICS_CHART_DATA";
export const RESET_DETAILED_ANALYTICS_CHART_DATA =
  "RESET_DETAILED_ANALYTICS_CHART_DATA";

// date range
export const SET_DATE_RANGE = "SET_DATE_RANGE";

// Theme
export const NIGHTMODE_TOGGLE = "NIGHTMODE_TOGGLE";

export const SIDEBAR_TOGGLE = "SIDEBAR_TOGGLE";

// notification
export const NOTIFICATION_LOADING = "NOTIFICATION_LOADING";
export const NOTIFICATION_SUCCESS = "NOTIFICATION_SUCCESS";
export const NOTIFICATION_FAILURE = "NOTIFICATION_FAILURE";

export const NOTIFICATION_MARKED_LOADING = "NOTIFICATION_MARKED_LOADING";
export const NOTIFICATION_MARKED_SUCCESS = "NOTIFICATION_MARKED_SUCCESS";
export const NOTIFICATION_MARKED_FAILURE = "NOTIFICATION_MARKED_FAILURE";
// settings
export const SETTING_LOADING = "SETTING_LOADING";
export const SETTING_SUCCESS = "SETTING_SUCCESS";
export const SETTING_FAILURE = "SETTING_FAILURE";

export const SETTING_EDIT_LOADING = "SETTING_EDIT_LOADING";
export const SETTING_EDIT_SUCCESS = "SETTING_EDIT_SUCCESS";
export const SETTING_EDIT_FAILURE = "SETTING_EDIT_FAILURE";

// payment
export const SUBSCRIPTION_PACKAGES_LOADING = "SUBSCRIPTION_PACKAGES_LOADING";
export const SUBSCRIPTION_PACKAGES_LOADED = "SUBSCRIPTION_PACKAGES_LOADED";
export const SUBSCRIPTION_PACKAGES_LOADING_ERROR =
  "SUBSCRIPTION_PLANS_LOADING_ERROR";

export const PAYMENT_AMOUNT_LOADING = "PAYMENT_AMOUNT_LOADING";
export const PAYMENT_AMOUNT_LOADED = "PAYMENT_AMOUNT_LOADED";
export const PAYMENT_AMOUNT_LOADING_ERROR = "PAYMENT_AMOUNT_LOADING_ERROR";

export const TRANSACTION_LOADING = "TRANSACTION_LOADING";
export const TRANSACTION_SUCCESS = "TRANSACTION_SUCCESS";
export const TRANSACTION_FAILURE = "TRANSACTION_FAILURE";
export const TRANSACTION_CLEAR = "TRANSACTION_CLEAR";

export const SUBSCRIPTION_STATUS_LOADING = "SUBSCRIPTION_STATUS_LOADING";
export const SUBSCRIPTION_STATUS_SUCCESS = "SUBSCRIPTION_STATUS_SUCCESS";
export const SUBSCRIPTION_STATUS_FAILURE = "SUBSCRIPTION_STATUS_FAILURE";

export const VENDOR_REGISTRATION_LOADING = "VENDOR_REGISTRATION_LOADING";
export const VENDOR_REGISTRATION_SUCCESS = "VENDOR_REGISTRATION_SUCCESS";
export const VENDOR_REGISTRATION_FAILURE = "VENDOR_REGISTRATION_FAILURE";

export const UPLOAD_PRODUCT_LOADING = "UPLOAD_PRODUCT_LOADING";
export const UPLOAD_PRODUCT_SUCCESS = "UPLOAD_PRODUCT_SUCCESS";
export const UPLOAD_PRODUCT_FAILURE = "UPLOAD_PRODUCT_FAILURE";

export const UPLOADED_PRODUCTS_LOADING = "UPLOADED_PRODUCTS_LOADING";
export const UPLOADED_PRODUCTS_SUCCESS = "UPLOADED_PRODUCTS_SUCCESS";
export const UPLOADED_PRODUCTS_FAILURE = "UPLOADED_PRODUCTS_FAILURE";

export const UPLOAD_DEAL_LOADING = "UPLOAD_DEAL_LOADING";
export const UPLOAD_DEAL_SUCCESS = "UPLOAD_DEAL_SUCCESS";
export const UPLOAD_DEAL_FAILURE = "UPLOAD_DEAL_FAILURE";

export const UPLOADED_DEALS_LOADING = "UPLOADED_DEALS_LOADING";
export const UPLOADED_DEALS_SUCCESS = "UPLOADED_DEALS_SUCCESS";
export const UPLOADED_DEALS_FAILURE = "UPLOADED_DEALS_FAILURE";

export const UPLOAD_PROCUREMENT_LOADING = "UPLOAD_PROCUREMENT_LOADING";
export const UPLOAD_PROCUREMENT_SUCCESS = "UPLOAD_PROCUREMENT_SUCCESS";
export const UPLOAD_PROCUREMENT_FAILURE = "UPLOAD_PROCUREMENT_FAILURE";

export const UPLOADED_PROCUREMENTS_LOADING = "UPLOADED_PROCUREMENTS_LOADING";
export const UPLOADED_PROCUREMENTS_SUCCESS = "UPLOADED_PROCUREMENTS_SUCCESS";
export const UPLOADED_PROCUREMENTS_FAILURE = "UPLOADED_PROCUREMENTS_FAILURE";

export const EVENTS_LOADING = "EVENTS_LOADING";
export const EVENTS_SUCCESS = "EVENTS_SUCCESS";
export const EVENTS_FAILURE = "EVENTS_FAILURE";

export const ACCOUNTING_RECONCILIATION_LOADING =
  "ACCOUNTING_RECONCILIATION_LOADING";
export const ACCOUNTING_RECONCILIATION_SUCCESS =
  "ACCOUNTING_RECONCILIATION_SUCCESS";
export const ACCOUNTING_RECONCILIATION_FAILURE =
  "ACCOUNTING_RECONCILIATION_FAILURE";

export const PROCUREMENT_INVOICING_LOADING = "PROCUREMENT_INVOICING_LOADING";
export const PROCUREMENT_INVOICING_SUCCESS = "PROCUREMENT_INVOICING_SUCCESS";
export const PROCUREMENT_INVOICING_FAILURE = "PROCUREMENT_INVOICING_FAILURE";

export const WAREHOUSES_SLOTS_LOADING = "WAREHOUSES_SLOTS_LOADING";
export const WAREHOUSES_SLOTS_SUCCESS = "WAREHOUSES_SLOTS_SUCCESS";
export const WAREHOUSES_SLOTS_FAILURE = "WAREHOUSES_SLOTS_FAILURE";

export const ACCOUNTING_PAYMENTS_LOADING = "ACCOUNTING_PAYMENTS_LOADING";
export const ACCOUNTING_PAYMENTS_SUCCESS = "ACCOUNTING_PAYMENTS_SUCCESS";
export const ACCOUNTING_PAYMENTS_FAILURE = "ACCOUNTING_PAYMENTS_FAILURE";

export const PRODUCT_PRICING_LOADING = "PRODUCT_PRICING_LOADING";
export const PRODUCT_PRICING_SUCCESS = "PRODUCT_PRICING_SUCCESS";
export const PRODUCT_PRICING_FAILURE = "PRODUCT_PRICING_FAILURE";

export const PRODUCT_PRICING_HISTORY_LOADING =
  "PRODUCT_PRICING_HISTORY_LOADING";
export const PRODUCT_PRICING_HISTORY_SUCCESS =
  "PRODUCT_PRICING_HISTORY_SUCCESS";
export const PRODUCT_PRICING_HISTORY_FAILURE =
  "PRODUCT_PRICING_HISTORY_FAILURE";

export const ACCOUNTING_ZIP_FILE_LOADING = "ACCOUNTING_ZIP_FILE_LOADING";
export const ACCOUNTING_ZIP_FILE_SUCCESS = "ACCOUNTING_ZIP_FILE_SUCCESS";
export const ACCOUNTING_ZIP_FILE_FAILURE = "ACCOUNTING_ZIP_FILE_FAILURE";

export const ACCOUNTING_RECEIVABLES_LOADING = "ACCOUNTING_RECEIVABLES_LOADING";
export const ACCOUNTING_RECEIVABLES_SUCCESS = "ACCOUNTING_RECEIVABLES_SUCCESS";
export const ACCOUNTING_RECEIVABLES_FAILURE = "ACCOUNTING_RECEIVABLES_FAILURE";

export const RECEIVABLE_PAYMENT_LOADING = "RECEIVABLE_PAYMENT_LOADING";
export const RECEIVABLE_PAYMENT_SUCCESS = "RECEIVABLE_PAYMENT_SUCCESS";
export const RECEIVABLE_PAYMENT_FAILURE = "RECEIVABLE_PAYMENT_FAILURE";
export const RESET_RECEIVABLE_PAYMENT = "RESET_RECEIVABLE_PAYMENT";

export const LIVE_STATUS_LOADING = "LIVE_STATUS_LOADING";
export const LIVE_STATUS_SUCCESS = "LIVE_STATUS_SUCCESS";
export const LIVE_STATUS_FAILURE = "LIVE_STATUS_FAILURE";

export const INVENTORY_FORECAST_LOADING = "INVENTORY_FORECAST_LOADING";
export const INVENTORY_FORECAST_SUCCESS = "INVENTORY_FORECAST_SUCCESS";
export const INVENTORY_FORECAST_FAILURE = "INVENTORY_FORECAST_FAILURE";

export const PERFORMANCE_GOAL_LOADING = "PERFORMANCE_GOAL_LOADING";
export const PERFORMANCE_GOAL_SUCCESS = "PERFORMANCE_GOAL_SUCCESS";
export const PERFORMANCE_GOAL_FAILURE = "PERFORMANCE_GOAL_FAILURE";

export const LATEST_REVIEWS_LOADING = "LATEST_REVIEWS_LOADING";
export const LATEST_REVIEWS_SUCCESS = "LATEST_REVIEWS_SUCCESS";
export const LATEST_REVIEWS_FAILURE = "LATEST_REVIEWS_FAILURE";

export const WORD_CLOUD_LOADING = "WORD_CLOUD_LOADING";
export const WORD_CLOUD_SUCCESS = "WORD_CLOUD_SUCCESS";
export const WORD_CLOUD_FAILURE = "WORD_CLOUD_FAILURE";

// Ticketing
export const TICKETS_LOADING = "TICKETS_LOADING";
export const TICKETS_SUCCESS = "TICKETS_SUCCESS";
export const TICKETS_FAILURE = "TICKETS_FAILURE";

export const CREATE_TICKET_LOADING = "CREATE_TICKET_LOADING";
export const CREATE_COMMENT_LOADING = "CREATE_COMMENT_LOADING";
export const REOPEN_TICKET_LOADING = "REOPEN_TICKET_LOADING";
export const CLOSE_TICKET_LOADING = "CLOSE_TICKET_LOADING";

// =====================================================================
// Version 1 Charts : V1
// nuggets data
export const SELLER_SALES_NUGGETS_FETCHED = "SELLER_SALES_NUGGETS_FETCHED";
export const VENDOR_SALES_NUGGETS_FETCHED = "VENDOR_SALES_NUGGETS_FETCHED";
export const SELLER_ADS_NUGGETS_FETCHED = "SELLER_ADS_NUGGETS_FETCHED";
export const VENDOR_ADS_NUGGETS_FETCHED = "VENDOR_ADS_NUGGETS_FETCHED";

export const sellerSalesNuggetActionTypes = {};
for (let i = 1; i < 6; i++) {
  sellerSalesNuggetActionTypes[
    `SELLER_SALES_NUGGET_${i}_DATA_LOADING`
  ] = `SELLER_SALES_NUGGET_${i}_DATA_LOADING`;
  sellerSalesNuggetActionTypes[
    `SELLER_SALES_NUGGET_${i}_DATA_LOADED`
  ] = `SELLER_SALES_NUGGET_${i}_DATA_LOADED`;
  sellerSalesNuggetActionTypes[
    `SELLER_SALES_NUGGET_${i}_DATA_LOAD_ERROR`
  ] = `SELLER_SALES_NUGGET_${i}_DATA_LOAD_ERROR`;
}

export const vendorSalesNuggetActionTypes = {};
for (let i = 1; i < 8; i++) {
  vendorSalesNuggetActionTypes[
    `VENDOR_SALES_NUGGET_${i}_DATA_LOADING`
  ] = `VENDOR_SALES_NUGGET_${i}_DATA_LOADING`;
  vendorSalesNuggetActionTypes[
    `VENDOR_SALES_NUGGET_${i}_DATA_LOADED`
  ] = `VENDOR_SALES_NUGGET_${i}_DATA_LOADED`;
  vendorSalesNuggetActionTypes[
    `VENDOR_SALES_NUGGET_${i}_DATA_LOAD_ERROR`
  ] = `VENDOR_SALES_NUGGET_${i}_DATA_LOAD_ERROR`;
}

// charts data
export const sellerSalesChartActionTypes = {};
for (let i = 6; i < 49; i++) {
  sellerSalesChartActionTypes[
    `SELLER_SALES_CHART_${i}_DATA_LOADING`
  ] = `SELLER_SALES_CHART_${i}_DATA_LOADING`;
  sellerSalesChartActionTypes[
    `SELLER_SALES_CHART_${i}_DATA_LOADED`
  ] = `SELLER_CHART_${i}_DATA_LOADED`;
  sellerSalesChartActionTypes[
    `SELLER_SALES_CHART_${i}_DATA_LOAD_ERROR`
  ] = `SELLER_CHART_${i}_DATA_LOAD_ERROR`;
}

export const vendorSalesChartActionTypes = {};
for (let i = 8; i < 13; i++) {
  vendorSalesChartActionTypes[
    `VENDOR_SALES_CHART_${i}_DATA_LOADING`
  ] = `VENDOR_SALES_CHART_${i}_DATA_LOADING`;
  vendorSalesChartActionTypes[
    `VENDOR_SALES_CHART_${i}_DATA_LOADED`
  ] = `VENDOR_SALES_CHART_${i}_DATA_LOADED`;
  vendorSalesChartActionTypes[
    `VENDOR_SALES_CHART_${i}_DATA_LOAD_ERROR`
  ] = `VENDOR_SALES_CHART_${i}_DATA_LOAD_ERROR`;
}

// ads nuggets data
export const sellerAdsNuggetActionTypes = {};
for (let i = 1; i < 6; i++) {
  sellerAdsNuggetActionTypes[
    `SELLER_ADS_NUGGET_${i}_DATA_LOADING`
  ] = `SELLER_ADS_NUGGET_${i}_DATA_LOADING`;
  sellerAdsNuggetActionTypes[
    `SELLER_ADS_NUGGET_${i}_DATA_LOADED`
  ] = `SELLER_ADS_NUGGET_${i}_DATA_LOADED`;
  sellerAdsNuggetActionTypes[
    `SELLER_ADS_NUGGET_${i}_DATA_LOAD_ERROR`
  ] = `SELLER_ADS_NUGGET_${i}_DATA_LOAD_ERROR`;
}

export const vendorAdsNuggetActionTypes = {};
for (let i = 1; i < 6; i++) {
  vendorAdsNuggetActionTypes[
    `VENDOR_ADS_NUGGET_${i}_DATA_LOADING`
  ] = `VENDOR_ADS_NUGGET_${i}_DATA_LOADING`;
  vendorAdsNuggetActionTypes[
    `VENDOR_ADS_NUGGET_${i}_DATA_LOADED`
  ] = `VENDOR_ADS_NUGGET_${i}_DATA_LOADED`;
  vendorAdsNuggetActionTypes[
    `VENDOR_ADS_NUGGET_${i}_DATA_LOAD_ERROR`
  ] = `VENDOR_ADS_NUGGET${i}_DATA_LOAD_ERROR`;
}

// ads charts data
export const sellerAdsChartActionTypes = {};
for (let i = 6; i < 12; i++) {
  sellerAdsChartActionTypes[
    `SELLER_ADS_CHART_${i}_DATA_LOADING`
  ] = `SELLER_ADS_CHART_${i}_DATA_LOADING`;
  sellerAdsChartActionTypes[
    `SELLER_ADS_CHART_${i}_DATA_LOADED`
  ] = `SELLER_ADS_CHART_${i}_DATA_LOADED`;
  sellerAdsChartActionTypes[
    `SELLER_ADS_CHART_${i}_DATA_LOAD_ERROR`
  ] = `SELLER_ADS_CHART_${i}_DATA_LOAD_ERROR`;
}

export const vendorAdsChartActionTypes = {};
for (let i = 6; i < 12; i++) {
  vendorAdsChartActionTypes[
    `VENDOR_ADS_CHART_${i}_DATA_LOADING`
  ] = `VENDOR_ADS_CHART_${i}_DATA_LOADING`;
  vendorAdsChartActionTypes[
    `VENDOR_ADS_CHART_${i}_DATA_LOADED`
  ] = `VENDOR_ADS_CHART_${i}_DATA_LOADED`;
  vendorAdsChartActionTypes[
    `VENDOR_ADS_CHART_${i}_DATA_LOAD_ERROR`
  ] = `VENDOR_ADS_CHART_${i}_DATA_LOAD_ERROR`;
}

// Analytics Charts Data
export const brandsChartActionTypes = {};
for (let i = 1; i < 49; i++) {
  brandsChartActionTypes[
    `BRANDS_CHART_${i}_DATA_LOADING`
  ] = `BRANDS_CHART_${i}_DATA_LOADING`;
  brandsChartActionTypes[
    `BRANDS_CHART_${i}_DATA_LOADED`
  ] = `BRANDS_CHART_${i}_DATA_LOADED`;
  brandsChartActionTypes[
    `BRANDS_CHART_${i}_DATA_LOAD_ERROR`
  ] = `BRANDS_CHART_${i}_DATA_LOAD_ERROR`;
}

export const vendorAnalyticsChartActionTypes = {};
for (let i = 1; i < 9; i++) {
  vendorAnalyticsChartActionTypes[
    `VENDOR_ANALYTICS_CHART_${i}_DATA_LOADING`
  ] = `VENDOR_ANALYTICS_CHART_${i}_DATA_LOADING`;
  vendorAnalyticsChartActionTypes[
    `VENDOR_ANALYTICS_CHART_${i}_DATA_LOADED`
  ] = `VENDOR_ANALYTICS_CHART_${i}_DATA_LOADED`;
  vendorAnalyticsChartActionTypes[
    `VENDOR_ANALYTICS_CHART_${i}_DATA_LOAD_ERROR`
  ] = `VENDOR_ANALYTICS_CHART_${i}_DATA_LOAD_ERROR`;
}

export const RESET_SELLER_SALES_CHART_DATA = "RESET_SELLER_SALES_CHART_DATA";
export const RESET_VENDOR_SALES_CHART_DATA = "RESET_VENDOR_SALES_CHART_DATA";
export const RESET_SELLER_ADS_CHART_DATA = "RESET_SELLER_ADS_CHART_DATA";
export const RESET_VENDOR_ADS_CHART_DATA = "RESET_VENDOR_ADS_CHART_DATA";
export const RESET_BRANDS_CHART_DATA = "RESET_BRANDS_CHART_DATA";
export const RESET_VENDOR_ANALYTICS_CHART_DATA =
  "RESET_VENDOR_ANALYTICS_CHART_DATA";
